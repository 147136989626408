import React, { useContext } from "react";

import { Link } from "react-router-dom";
import {
  IconButton,
  Avatar,
  Box,
  CloseButton,
  Flex,
  HStack,
  VStack,
  Icon,
  useColorModeValue,
  Drawer,
  DrawerContent,
  Text,
  useDisclosure,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Image,
} from "@chakra-ui/react";
import { AuthContext } from "../../src/contexts/Auth";
import { FiSettings, FiMenu, FiBell, FiChevronDown } from "react-icons/fi";
import Logo from "../images/logo.png";

const LinkItems = [
  { name: "Home", icon: FiSettings, url: "/home/admin" },
  { name: "Usuários", icon: FiSettings, url: "/users" },
  { name: "Matérias", icon: FiSettings, url: "/materias" },
];

export default function SidebarWithHeader({ children }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const user = JSON.parse(localStorage.getItem("user"));
  const { logout } = useContext(AuthContext);

  const handleLogout = () => {
    logout();
  };

  return (
    <Box>
      {user.role_id !== 3 && (
        <SidebarContent
          onClose={() => onClose}
          handleLogout={handleLogout}
          display={{ base: "none", md: "block" }}
          boxShadow={"lg"}
        />
      )}
      <Drawer
        autoFocus={false}
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size="full"
      >
        <DrawerContent>
          <SidebarContent onClose={onClose} />
        </DrawerContent>
      </Drawer>
      {/* mobilenav */}
      <MobileNav onOpen={onOpen} handleLogout={handleLogout} />

      <Box ml={{ base: 0, md: 60 }} p="4">
        {children}
      </Box>
    </Box>
  );
}

const SidebarContent = ({ onClose, handleLogout, ...rest }) => {
  return (
    <Box
      transition="3s ease"
      bg={useColorModeValue("white", "gray.900")}
      borderRight="1px"
      borderRightColor={useColorModeValue("gray.200", "gray.700")}
      w={{ base: "full", md: 60 }}
      pos="fixed"
      h="full"
      {...rest}
    >
      <Flex
        h="20"
        alignItems="center"
        mx="8"
        justifyContent="space-between"
        mb={"40px"}
      >
        <Image src={Logo} maxWidth="50%" />
        <CloseButton display={{ base: "flex", md: "none" }} onClick={onClose} />
      </Flex>
      {LinkItems.map((link) => (
        <Link to={link.url}>
          <NavItem key={link.name} icon={link.icon}>
            {link.name}
          </NavItem>
        </Link>
      ))}
      <NavItem
        onClick={handleLogout}
        mt={5}
        fontWeight={"400"}
        position={"absolute"}
        bottom={5}
      >
        Sair
      </NavItem>
    </Box>
  );
};

const NavItem = ({ icon, children, ...rest }) => {
  return (
    <Flex
      align="center"
      p="4"
      mx="4"
      borderRadius="lg"
      role="group"
      cursor="pointer"
      _hover={{
        bg: "#1A25FF",
        color: "white",
      }}
      {...rest}
    >
      {icon && (
        <Icon
          mr="4"
          fontSize="16"
          _groupHover={{
            color: "white",
          }}
          as={icon}
        />
      )}
      {children}
    </Flex>
  );
};

const MobileNav = ({ onOpen, handleLogout, ...rest }) => {
  const user = JSON.parse(localStorage.getItem("user"));
  const { logout } = useContext(AuthContext);

  return (
    <Flex
      ml={user.role_id != 3 ? { base: 0, md: 60 } : { base: 0, md: 0 }}
      px={{ base: 4, md: 4 }}
      boxShadow={"md"}
      height="20"
      alignItems="center"
      bg={useColorModeValue("white", "gray.900")}
      borderBottomWidth="1px"
      borderBottomColor={useColorModeValue("gray.200", "gray.700")}
      justifyContent={{ base: "space-between", md: "flex-end" }}
      {...rest}
    >
      {user.role_id != 3 ? (
        <IconButton
          display={{ base: "flex", md: "none" }}
          onClick={onOpen}
          variant="outline"
          aria-label="open menu"
          icon={<FiMenu />}
        />
      ) : (
        ""
      )}

      <Image
        display={{ base: "flex", md: "none" }}
        fontSize="2xl"
        fontFamily="monospace"
        fontWeight="bold"
        src={Logo}
        maxWidth="50%"
      />

      <HStack spacing={{ base: "0", md: "6" }}>
        <Flex alignItems={"center"}>
          <Menu>
            <MenuButton
              py={2}
              transition="all 0.3s"
              _focus={{ boxShadow: "none" }}
            >
              <HStack>
                <VStack
                  display={{ base: "none", md: "flex" }}
                  alignItems="flex-start"
                  spacing="1px"
                  ml="2"
                >
                  <Text fontSize="sm">{user.name}</Text>
                </VStack>
                {user.role_id === 3 ? (
                  <>
                    <Box display={{ base: "none", md: "flex" }}>
                      <FiChevronDown />
                    </Box>
                  </>
                ) : (
                  ""
                )}
              </HStack>
            </MenuButton>
            {user.role_id === 3 ? (
              <>
                <MenuList>
                  <MenuItem>Meu perfil</MenuItem>
                  <MenuItem>Meus Cadernos</MenuItem>
                  <MenuDivider />
                  <MenuItem onClick={handleLogout}>Sign out</MenuItem>
                </MenuList>
              </>
            ) : (
              ""
            )}
          </Menu>
        </Flex>
      </HStack>
    </Flex>
  );
};
