import { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import Navbar from "../../Components/Navbar";
import {
  Box,
  Heading,
  Stack,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Button,
  Td,
} from "@chakra-ui/react";
import { UserContext } from "../../contexts/User";

const HomeAdmin = () => {
  const navigate = useNavigate();

  const { listUsersApi } = useContext(UserContext);
  const [users, setUsers] = useState([]);

  const handleSelectUser = (user, id) => {
    localStorage.setItem("userInfoID", id);
    navigate(`/user/${user}`);
  };

  const getUsers = async () => {
    try {
      const usersData = await listUsersApi();
      setUsers(usersData.data?.[0]);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const recoveredUser = JSON.parse(localStorage.getItem("user"));
    console.log(recoveredUser);
    if (recoveredUser.role_id === 1) {
      getUsers();
    } else if (recoveredUser.role_id === 3) {
      navigate(`/home/${recoveredUser.name}`);
    }
  }, []);
  return (
    <>
      <Navbar />
      <Box
        p={8}
        ml={{ base: 0, md: 60 }}
        bgColor={"#f0f8ff"}
        minH={"100vh"}
        mt={"-35px"}
      >
        <Stack spacing={8}>
          <Box>
            <Heading size="md" mb={4}>
              Lista de Alunos
            </Heading>
            <Table variant="striped">
              <Thead>
                <Tr>
                  <Th>ID</Th>
                  <Th>Nome</Th>
                  <Th>E-mail</Th>
                  <Th>Tipo</Th>
                  <Th>Ações</Th>
                </Tr>
              </Thead>
              <Tbody>
                {users.map((user) => (
                  <Tr key={user.id}>
                    <Td>{user.name}</Td>
                    <Td>{user.email}</Td>
                    <Td>{user.email}</Td>
                    <Td>
                      <Button
                        colorScheme="teal"
                        size={"sm"}
                        onClick={() => handleSelectUser(user.name, user.id)}
                      >
                        Ver usuário
                      </Button>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </Box>
        </Stack>
      </Box>
    </>
  );
};

export default HomeAdmin;
